import { useState, useEffect } from 'react'
import { Switch } from "@chakra-ui/react";
import secureApiFetch from 'services/api';
import { actionCompletedToast } from "components/ui/toast";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Tooltip } from '@chakra-ui/react'
import Configuration from 'Configuration';

const Verify = ({ project, updateProject }) => {
    const [checked, setChecked] = useState(true);
    const domainName = Configuration.getClientUrl()
    const url = `${domainName}/verification.html?id=${project.public_id}`;

    useEffect(() => {
        if (project.public_link === 1) {
            setChecked(true)
        } else {
            setChecked(false)
        }
    }, [project.public_link])

    const handleVisibilty = () => {
        let confirm = checked === false ? window.confirm('This action will make this project publicly verifiable. Displaying only the engagement date, vulnerability count, and your name. You can use this to share with your stakeholders and third parties.') :
            window.confirm('This action will make this project "Private" and not verifiable with public link.')
        if (confirm) {
            const value = !checked;
            secureApiFetch(`/projects/${project.id}/verify?publicLink=${value}`, {
                method: 'PATCH',
            })
                .then(() => {
                    updateProject();
                    actionCompletedToast('Visibility has been updated');
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleCopyButton = () => {
        navigator.clipboard.writeText(url)
        actionCompletedToast('The link has been copied to your clipboard.');
    }

    return (
        <div>
            <div className="border px-2 py-1.5 rounded flex items-center">
                <label className='font-medium'>
                    {checked === true ? 'Publicly Accessible' : 'Private'}
                </label>
                <Switch isChecked={checked} onChange={handleVisibilty} />
                {checked === true && (
                    <Tooltip hasArrow label={url} bg='gray.300' color='black'>
                        <button onClick={handleCopyButton} className="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded hover:bg-gray-200 flex items-center">
                            <ContentCopyIcon fontSize='small' />
                        </button>
                    </Tooltip>
                )}
            </div>

            {/* <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Project Verification Link</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <input value={url} readOnly className='w-full' />
                        <span className='copy-btn cursor-pointer' onClick={() => { navigator.clipboard.writeText(url) }}>
                            <ContentCopyIcon fontSize='small' />
                        </span>
                    </ModalBody>
                </ModalContent>
            </Modal> */}
        </div>
    );
};

export default Verify;
