const TabsUrl = [
    'details',
    'targets',
    'tasks',
    'vulnerabilities',
    'discussion',
    'attachments',
    'vault',
];
export default TabsUrl;

export const TabsUrlVulnerability = [
    'description',
    'remediation',
    'discussion',
    'attachments',
];
