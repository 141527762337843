import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from '@chakra-ui/react';

import RestrictedComponent from "components/logic/RestrictedComponent";
import NoResultsTableRow from 'components/ui/tables/NoResultsTableRow';
import LoadingTableRow from 'components/ui/tables/LoadingTableRow';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import Markdown from 'react-markdown'

import BadgeOutline from '../badges/BadgeOutline';
import UserLink from "../users/Link";
import TaskBadge from 'components/tasks/TaskBadge';
import TaskStatusFormatter from 'components/tasks/TaskStatusFormatter';

const TaskTableProject = ({ tableModel, tableModelSetter: setTableModel, destroy, reloadCallback }) => {
    useEffect(() => {
        setTableModel(prevTableModel => ({
            ...prevTableModel,
            selection: []
        }));
    }, [reloadCallback, setTableModel]);

    const onSelectionChange = ev => {
        const target = ev.target;
        const selectionId = parseInt(target.value);
        if (target.checked) {
            setTableModel({ ...tableModel, selection: [...tableModel.selection, selectionId] })
        } else {
            setTableModel({ ...tableModel, selection: tableModel.selection.filter(value => value !== selectionId) })
        }
    };
    const onHeaderCheckboxClick = ev => {
        if (ev.target.checked) {
            setTableModel({ ...tableModel, selection: tableModel.tasks.map(task => task.id) })

        } else {
            setTableModel({ ...tableModel, selection: [] })
        }
    }
    const tasksLength = null !== tableModel.tasks ? tableModel.tasks.length : 0;

    const getStatusColor = (status) => {
        const statusMappings = {
            'Highest': 'bg-red-100 text-red-800 border border-red-300',
            'High': 'bg-orange-100 text-orange-800 border border-orange-300',
            'Medium': 'bg-yellow-100 text-yellow-800 border border-yellow-300',
            'Low': 'bg-blue-100 text-blue-800 border border-blue-300',
            'Lowest': 'bg-green-100 text-green-800 border border-green-300',
        };

        return statusMappings[status] || 'bg-gray-100 text-gray-800';
    };

    return (
        <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
            <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                    <tr>
                        <th scope="col" className="px-2 py-2">
                            <Checkbox borderColor='gray.300' onChange={onHeaderCheckboxClick} isChecked={tableModel.selection.length && tableModel.selection.length === tasksLength} isDisabled={tableModel.tasksLength === 0} />
                        </th>
                        <th scope="col" className="px-2 py-2">SUMMARY</th>
                        <th scope="col" className="px-2 py-2"> DESCRIPTION</th>
                        <th scope="col" className="px-2 py-2">PRIORITY</th>
                        <th scope="col" className="px-2 py-2"> ASSIGNEE </th>
                        <th scope="col" className="px-2 py-2">STATUS</th>
                        <th scope="col" className="px-2 py-2">COMMAND</th>
                        <RestrictedComponent roles={['administrator', 'superuser']}>
                            <th scope="col" className="px-2 py-2">Actions</th>
                        </RestrictedComponent>
                    </tr>
                </thead>
                <tbody>
                    {null === tableModel.tasks && <LoadingTableRow numColumns={8} />}
                    {null !== tableModel.tasks && 0 === tableModel.tasks.length && <NoResultsTableRow numColumns={10} />}
                    {null !== tableModel.tasks && tableModel.tasks.map(task => (
                        <React.Fragment key={task.id}>
                            <tr className="border-b border-gray-300">
                                <td className="px-2 py-1">
                                    <Checkbox borderColor='gray.400' value={task.id} onChange={onSelectionChange} isChecked={tableModel.selection.includes(task.id)}></Checkbox>
                                </td>
                                <td className="px-2 py-1">
                                    <TaskBadge task={task} />
                                    {task.maintask && (
                                        <span className='block'>
                                            <span className='bg-blue-100 text-blue-800 text-[9px] font-medium me-2 px-2.5 py-0.5 rounded  border border-blue-400'>Sub-Task</span>
                                            <Link className='bg-blue-100 text-blue-800 text-[9px] font-medium me-2 px-2.5 py-0.5 rounded  border border-blue-400' to={"/tasks/" + task.maintask}>{task.maintask}</Link>
                                        </span>
                                    )}
                                </td>

                                <td className="px-2 ">
                                    <Markdown>
                                        {task.description ? task.description.substring(0, 100) + "..." : "-"}
                                    </Markdown>
                                </td>

                                <td className="px-2 py-1">
                                    <span className={`text-xs font-medium inline-flex px-2 py-0.5 rounded  ${getStatusColor(task.priority)}`}>
                                        {task.priority || '(undefined)'}
                                    </span>
                                </td>

                                <td className="px-2 py-1">{task.assignee_uid ? <UserLink userId={task.assignee_uid}>{task.assignee_full_name}</UserLink> : '(nobody)'}  </td>

                                <td className="px-2 py-1"> <TaskStatusFormatter task={task} /> </td>

                                <td className="px-2 py-1"> {task.command_name ? <BadgeOutline id={task.command_id}>{task.command_name}</BadgeOutline> : '-'} </td>

                                <RestrictedComponent roles={['administrator', 'superuser']}>
                                    <td className="px-2 py-1">
                                        <span className='flex space-x-1'>
                                            <Link to={`/tasks/${task.id}/edit`}> <ModeEditIcon className='text-[#3A3D89]' fontSize='small' /> </Link>
                                            {destroy && <button onClick={() => destroy(task.id)}>
                                                <DeleteIcon className='text-[#EB1F36]' fontSize='small' />
                                            </button>}
                                        </span>
                                    </td>
                                </RestrictedComponent>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
}


export default TaskTableProject