import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ServerIssuesUrl, UserManualUrl } from 'ServerUrls';
import { AuthConsumer } from '../../contexts/AuthContext';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MenuIcon from '@mui/icons-material/Menu';

import NotificationsBadge from '../notifications/NotificationsBadge';
import LinkButton from '../ui/buttons/Link';
import HeaderUserMenu from '../ui/HeaderUserMenu';
import './Header.scss';
import NavSearch from 'components/search/NavSearch';

// import { useEffect } from 'react';
// import SearchBox from "../search/Box";
// import secureApiFetch from 'services/api';
// import Cookies from 'js-cookie'
// import HeaderLogo from './HeaderLogo';

const LINKS = [
    { title: "User Manual", to: { pathname: UserManualUrl } },
    { title: "Support", to: { pathname: ServerIssuesUrl } },
];
const Header = ({ setSidebarCollapsed, sidebarCollapsed }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarCollapsed(!sidebarCollapsed);
        setIsOpen(!isOpen);
    };

    // useEffect(() => {
    //     secureApiFetch(`/users/login`, {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         credentials: 'include'
    //     })
    //         .then((response) => {
    //             const cookieHeader = response.headers.get('Set-Cookie');
    //             if (cookieHeader) {
    //                 const cookies = cookieHeader.split(';');
    //                 cookies.forEach(cookie => {
    //                     const [name, value] = cookie.split('=');
    //                     Cookies.set(name, value.trim(), {
    //                         expires: 7,
    //                         path: '/',
    //                         domain: 'api-stage.example.com',
    //                         samesite: "None",
    //                         secure: true
    //                     });
    //                 });
    //             }
    //             return response.json()
    //         })
    // }, [])



    return <AuthConsumer>{
        ({ isAuth, user }) => (
            <nav className="top-header">
                <span onClick={toggleSidebar}
                    className={`transform ${isOpen ? 'rotate-180' : 'rotate-0'} transition duration-700 border p-1.5 rounded-full flex items-center justify-center cursor-pointer`}>
                    {isOpen
                        ? <MenuIcon fontSize='small' />
                        : <ArrowBackIosNewIcon fontSize='small' />
                    }
                </span>
                <Link to='/' className="logo">
                    {/* <HeaderLogo /> */}
                </Link>
                <NavSearch />
                {isAuth ? <>
                    {/* <SearchBox /> */}
                    <NotificationsBadge />
                    {user && <HeaderUserMenu email={user.email} />}
                </>
                    : LINKS.map((link, index) => (
                        <LinkButton external key={index} href={link.to.pathname}>
                            {link.title}
                        </LinkButton>))}
            </nav>
        )
    }
    </AuthConsumer>
}

export default Header;
