import React from "react";
import { List, ListItem } from '@chakra-ui/react';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, FormLabel, HStack, Stack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { actionCompletedToast } from "components/ui/toast";
import { Radio, RadioGroup } from '@chakra-ui/react'
import useFetch from "hooks/useFetch";
import { ReactComponent as TargetIcon } from 'images/icons/target.svg';
import { useEffect, useState, useMemo } from "react";
import secureApiFetch from "services/api";
import { useDropzone } from 'react-dropzone';
import ProcessingLogo from "components/ui/process logo/ProcessingLogo";
import ModalTable from "./ModalTable";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 'var(--borderWidth)',
    borderRadius: 'var(--borderRadius)',
    borderColor: 'var(--color-gray)',
    borderStyle: 'dashed',
    backgroundColor: 'var(--black)',
    color: 'var(--text-color)',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: 'var(--yellow)'
};

const acceptStyle = {
    borderColor: 'var(--green)'
};

const rejectStyle = {
    borderColor: 'var(--red)'
};

const ReportVersionModalDialog = ({ projectId, isOpen, onSubmit, onCancel }) => {
    const defaultFormValues = { reportTemplateId: 0, name: "", description: "", valid_till: "", vulnerability_stamp: "" };
    const [formValues, setFormValues] = useState(defaultFormValues);
    const [templates] = useFetch('/reports/templates');
    const [value, setValue] = React.useState('1')
    const [acceptedFiles, setAcceptedFiles] = useState([]);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const [loading, setLoading] = useState(false)

    const onFileDrop = (newFiles) => {
        setAcceptedFiles(newFiles);
    };

    const {
        getRootProps, getInputProps,
        isDragActive, isDragAccept, isDragReject
    } = useDropzone({ onDrop: onFileDrop });


    const onFormValueChange = ev => {
        ev.preventDefault();
        setFormValues({ ...formValues, [ev.target.name]: ev.target.value });
    };

    const handleDateCount = (e) => {
        let value = e.target.value;
        let timestamp = Date.parse(value);
        const now = new Date();
        const diffInTime = timestamp - now;
        const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));
        setFormValues({ ...formValues, [e.target.name]: diffInDays });
    };


    const beforeCancelCallback = ev => {
        setFormValues(defaultFormValues);
        onCancel(ev);
    }

    const onFormSubmit = (ev) => {
        ev.preventDefault()
        setIsSaveDisabled(true);
        setLoading(true)
        const params = {
            projectId: projectId,
            reportTemplateId: formValues.reportTemplateId,
            name: formValues.name,
            description: formValues.description,
            valid_till: formValues.valid_till
        };

        const formData = new FormData();
        formData.append('version_name', formValues.name);
        formData.append('version_description', formValues.description);
        formData.append('valid_till', formValues.valid_till);
        formData.append('vulnerability_stamp', formValues.vulnerability_stamp);
        acceptedFiles.forEach(file => {
            formData.append('resultFile', file);
        })

        if (value === '1') {
            secureApiFetch(`/reports`, {
                method: 'POST',
                body: JSON.stringify(params)
            })
                .then(() => {
                    onSubmit();
                    onCancel()
                    setLoading(false)
                    actionCompletedToast(`The report version "${formValues.name}" has been added.`);
                    setIsSaveDisabled(false);
                })
                .catch(err => {
                    console.error(err);
                    setIsSaveDisabled(false);
                })
                .finally(() => {
                    setFormValues(defaultFormValues)
                    setTimeout(() => {
                        setIsSaveDisabled(false);
                    }, 3500);
                })
        } else {
            secureApiFetch(`/reports/${projectId}/manual`, {
                method: 'POST',
                body: formData
            })
                .then(() => {
                    setLoading(false)
                    onSubmit();
                    setAcceptedFiles([]);
                    actionCompletedToast(`The report version "${formValues.name}" has been added.`);
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => {
                    setFormValues(defaultFormValues)
                })
        }
    }

    useEffect(() => {
        if (templates !== null && templates.length > 0) {
            setFormValues((prev) => ({ ...prev, reportTemplateId: templates[0].id }))
        }
    }, [templates]);

    useEffect(() => {
        console.log('formValues', formValues)
    }, [formValues])

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragAccept,
        isDragReject
    ]);
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const minDate = tomorrow.toISOString().split('T')[0];

    return (
        <div className="text-gray-700">
            <Modal size="2xl" isOpen={isOpen} onClose={beforeCancelCallback}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader><HStack><TargetIcon style={{ width: '24px' }} /> <h4>New report version details</h4></HStack></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form id="reportVersionReportForm" onSubmit={onFormSubmit} className="crud -mt-8" >
                            <FormControl isRequired>
                                <label className="font-bold text-lg">Select Template</label>
                                {value === '1' ?
                                    (<div>
                                        {templates &&
                                            <select name="reportTemplateId" value={formValues.reportTemplateId} onChange={onFormValueChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' >
                                                {templates.map(template => <option key={template.id} value={template.id}>{template.version_name}</option>)}
                                            </select>
                                        }
                                    </div>) : (
                                        <div className="container">
                                            <div {...getRootProps({ style })}>
                                                <input {...getInputProps()} />
                                                <p>Drag and drop some files here, or click to select files</p>
                                            </div>
                                            <aside>
                                                {acceptedFiles.length === 0 && <div>(upload list empty)</div>}
                                                {acceptedFiles.length > 0 && <List spacing={3}>{acceptedFiles.map(file => (
                                                    <ListItem key={file.path}>
                                                        <FontAwesomeIcon color='var(--primary-color)' icon={faUpload} /> {file.path} - {file.size} bytes
                                                    </ListItem>
                                                ))}</List>}
                                            </aside>
                                        </div>
                                    )
                                }
                                <RadioGroup onChange={setValue} value={value}>
                                    <Stack direction='row'>
                                        <Radio value='1'>Template</Radio>
                                        <Radio value='2'>Manual</Radio>
                                    </Stack>
                                </RadioGroup>

                            </FormControl >
                            <FormControl isRequired>
                                <FormLabel>File Name</FormLabel>
                                <input className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' type="text" name="description" value={formValues.description} onChange={onFormValueChange} placeholder="eg Initial version, Draft" />
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel>Version</FormLabel>
                                <input className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' type="text" name="name" value={formValues.name} onChange={onFormValueChange} placeholder="eg 1.0, 202103" />
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel>Expiray Date</FormLabel>
                                <input type="date" name="valid_till" min={minDate} onChange={handleDateCount} placeholder="eg Initial version, Draft" className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
                            </FormControl>
                            {value !== '1' && (
                                <ModalTable formValues={formValues} setFormValues={setFormValues} />
                            )}
                        </form>
                    </ModalBody>

                    <ModalFooter>
                        <button onClick={beforeCancelCallback} className='bg-red-500 px-4 mx-2 py-2 rounded text-white text-sm'>
                            Cancel
                        </button>
                        <button form="reportVersionReportForm" type="submit" disabled={isSaveDisabled} className="bg-[#3A3D89] px-4 py-2 rounded text-white text-sm flex items-center">
                            Add Report
                            {loading && (
                                <ProcessingLogo textColor='text-white' />
                            )}
                        </button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default ReportVersionModalDialog;
