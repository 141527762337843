import React, { useState } from 'react'

const ModalTable = ({ formValues, setFormValues }) => {
    const [data, setData] = useState([
        { open: 0, risk: "Critical", total: 0, closed: 0, resolved: 0, confirmed: 0 },
        { open: 0, risk: "High", total: 0, closed: 0, resolved: 0, confirmed: 0 },
        { open: 0, risk: "Medium", total: 0, closed: 0, resolved: 0, confirmed: 0 },
        { open: 0, risk: "Low", total: 0, closed: 0, resolved: 0, confirmed: 0 },
        { open: 0, risk: "None", total: 0, closed: 0, resolved: 0, confirmed: 0 },
    ]);

    const handleChange = (index, field, value) => {
        const updatedData = data.map((row, i) => {
            if (i === index) {
                const updatedRow = {
                    ...row,
                    [field]: parseInt(value) || 0,
                };
                updatedRow.total = updatedRow.open + updatedRow.closed + updatedRow.resolved + updatedRow.confirmed;
                return updatedRow;
            }
            return row;
        });
        setData(updatedData);
        setFormValues({ ...formValues, vulnerability_stamp: JSON.stringify(updatedData) })
    };
    return (
        <div className="bg-white rounded-lg">
            <table className="min-w-full border-collapse">
                <thead>
                    <tr className=' border-b-2 border-gray-200 bg-gray-100 font-semibold text-gray-600'>
                        <th className="py-3 px-6 text-left text-xs "> Risk </th>
                        <th className="py-3 px-6 text-left text-xs"> Open </th>
                        <th className="py-3 px-6 text-left text-xs"> Closed </th>
                        <th className="py-3 px-6 text-left text-xs"> Resolved </th>
                        <th className="py-3 px-6 text-left text-xs"> Confirmed </th>
                        <th className="py-3 px-6 text-left text-xs"> Total </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr key={index} className="odd:bg-gray-50 even:bg-white">
                            <th className="py-1 px-6 border-b border-gray-200 text-left text-sm font-medium text-gray-800">
                                {row.risk}
                            </th>
                            <td className="py-1 px-6 border-b border-gray-200">
                                <input
                                    type="number"
                                    value={row.open}
                                    onChange={(e) => handleChange(index, 'open', e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </td>
                            <td className="py-1 px-6 border-b border-gray-200">
                                <input
                                    type="number"
                                    value={row.closed}
                                    onChange={(e) => handleChange(index, 'closed', e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </td>
                            <td className="py-1 px-6 border-b border-gray-200">
                                <input
                                    type="number"
                                    value={row.resolved}
                                    onChange={(e) => handleChange(index, 'resolved', e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </td>
                            <td className="py-1 px-6 border-b border-gray-200">
                                <input
                                    type="number"
                                    value={row.confirmed}
                                    onChange={(e) => handleChange(index, 'confirmed', e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </td>
                            <td className="py-1 px-6 border-b border-gray-200 text-sm text-gray-800">
                                {row.total}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default ModalTable