import React from 'react'

function UserManual() {
  return (
    <div className="bg-gray-100 min-h-screen  flex justify-center">
      <div className="bg-white p-4 ">
        <div className="text-3xl font-semibold text-[#3A3D89] mb-6">Help and Support</div>
        <hr className='border-t-2 border-gray-300 mb-6' />
        
        <h5 className="text-xl font-semibold text-[#3A3D89] mb-4">Understanding Vulnerability Status</h5>
        <div className="text-base leading-7 mb-6">
          Welcome to our guide on navigating vulnerability statuses within our Penetration Testing as a Service (PTaaS) Platform. As part of our commitment to providing you with comprehensive cybersecurity solutions, this article is designed to help you understand the lifecycle of a vulnerability within our system and how you can effectively respond to ensure your network remains secure.
        </div>

        <h5 className="text-xl font-semibold text-[#3A3D89] mb-4">Introduction to Vulnerability Statuses</h5>
        <div className="text-base leading-7 mb-6">
          In our portal, vulnerabilities detected during tests are assigned statuses that reflect their current state from discovery to resolution. These statuses are crucial for prioritizing actions and understanding the threat level. Here’s what each status means, and the actions required from your end.
        </div>

        {/* Status 1: Open - Report */}
        <div className="text-lg font-semibold text-[#3A3D89] mt-6">1. <span className="text-red-600">Open</span> - Report</div>
        <div className="text-base leading-7 mt-2">
          <span className="font-semibold">What It Means:</span> This is the initial status of any newly discovered vulnerability. It indicates that the issue has been documented, but not yet published or fully assessed.
        </div>
        <div className="text-base leading-7 mt-2">
          <span className="font-semibold">Your Role:</span> No immediate action is required from you at this stage. Our internal Quality Assurance (QA) or penetration testing team will be reviewing the vulnerability for further classification.
        </div>

        {/* Status 2: Confirmed - Unexploited/Exploited */}
        <div className="text-lg font-semibold text-[#3A3D89] mt-6">2. <span className="text-red-600">Confirmed</span> - Unexploited/Exploited</div>
        <div className="text-base leading-7 mt-2">
          <span className="font-semibold">What It Means:</span> After initial assessment, vulnerabilities are classified as 'Confirmed.' If it's marked 'Unexploited,' it means the vulnerability exists but hasn't been abused. Conversely, 'Exploited' means there is evidence the vulnerability has been used maliciously.
        </div>
        <div className="text-base leading-7 mt-2">
          <span className="font-semibold">Your Role:</span> This stage requires your attention. You'll need to log into the client portal and decide whether to mitigate (reduce the risk) or remediate (completely resolve) the vulnerability. Our system provides detailed guidance on possible actions.
        </div>

        {/* Status 3: Resolved - Mitigated/Remediated */}
        <div className="text-lg font-semibold text-[#3A3D89] mt-6">3. <span className="text-red-600">Resolved</span> - Mitigated/Remediated</div>
        <div className="text-base leading-7 mt-2">
          <span className="font-semibold">What It Means:</span> Once you've taken action, the status changes to 'Resolved,' indicating that steps have been taken to address the vulnerability. It can be either 'Mitigated' if the risk is reduced or 'Remediated' if the issue is fully resolved.
        </div>
        <div className="text-base leading-7 mt-2">
          <span className="font-semibold">Your Role:</span> Having already taken the necessary steps, you can monitor the status and await confirmation from the QA or pen test team.
        </div>

        {/* Status 4: Closed - Remediated/Mitigated/Accepted */}
        <div className="text-lg font-semibold text-[#3A3D89] mt-6">4. <span className="text-red-600">Closed</span> - Remediated/Mitigated/Accepted</div>
        <div className="text-base leading-7 mt-2">
          <span className="font-semibold">What It Means:</span> Closure statuses are good news, meaning the vulnerability is effectively dealt with. 'Closed - Remediated' signifies a full resolution, 'Closed - Mitigated' indicates acceptable risk reduction, and 'Closed - Accepted' means the risk is acknowledged with no further action planned.
        </div>
        <div className="text-base leading-7 mt-2">
          <span className="font-semibold">Your Role:</span> No further action is required. You can review the final reports and documentation for your records and compliance needs.
        </div>

        {/* Status 5: Open - Unresolved */}
        <div className="text-lg font-semibold text-[#3A3D89] mt-6">5. <span className="text-red-600">Open</span> - Unresolved</div>
        <div className="text-base leading-7 mt-2">
          <span className="font-semibold">What It Means:</span> If a vulnerability is found to be inadequately addressed upon retesting, it will revert to 'Open - Unresolved.' This indicates that the issue persists and requires additional attention.
        </div>
        <div className="text-base leading-7 mt-2">
          <span className="font-semibold">Your Role:</span> You need to revisit the vulnerability in the client portal and take further action to ensure it is adequately addressed.
        </div>

        <h5 className="text-xl font-semibold text-[#3A3D89] mt-8">Taking Action: Your Client Portal</h5>
        <div className="text-base leading-7 mb-6">
          Our client portal is designed to provide you with a user-friendly interface to manage and respond to vulnerabilities. Here you can:
        </div>
        <ul className="list-disc list-inside mb-6">
          <li className="leading-7">View detailed information about each vulnerability.</li>
          <li className="leading-7">Take action to mitigate or remediate issues.</li>
          <li className="leading-7">Communicate directly with our QA or pen test team.</li>
          <li className="leading-7">Access reports and historical data for trend analysis and compliance.</li>
        </ul>

        <h5 className="text-xl font-semibold text-[#3A3D89] mt-8">Conclusion</h5>
        <div className="text-base leading-7 mb-6">
          Understanding the various statuses of vulnerabilities in our PTaaS platform is key to maintaining robust security. By staying informed and responsive, you can ensure that vulnerabilities are effectively managed and resolved. Remember, our team is always here to support you through each step of the process, from detection to resolution.
        </div>
        <div className="text-base leading-7 mb-6">
          For further assistance or any questions regarding your vulnerabilities, please don't hesitate to contact our support team through the client portal. Your security is our priority, and we are dedicated to helping you maintain a resilient and secure digital environment.
        </div>

        <div className="flex justify-center mt-8">
          <img src={`/vulneability_status.png`} alt="Security Image" className="w-80 shadow-md rounded-md" />
        </div>
      </div>
    </div>
  )
}

export default UserManual
