import Ipv4Link from 'components/ui/Ipv4Link';
import NoResultsTableRow from 'components/ui/tables/NoResultsTableRow';
import UserAgentLabel from 'components/ui/UserAgentLabel';
import UserLink from 'components/users/Link';

const AuditLogsTable = ({ auditLog, hideUserColumns = false }) => {
    const numColumns = hideUserColumns ? 4 : 6;

    const roleColors = (status) => {
        const statusMappings = {
            'administrator': 'text-red-500',
            'superuser': 'text-blue-500',
            'user': 'text-green-500',
            'client': 'text-yellow-500',
        };
        return statusMappings[status] || 'bg-gray-100 text-gray-800';
    };

    // const renderObject = (obj) => {
    //     const breakText = (text, maxLength) => {
    //         const regex = new RegExp(`(.{1,${maxLength}})`, 'g');
    //         return text.match(regex).join('\n');
    //     };
    
    //     return (
    //         <ul className="list-disc pl-4">
    //             {Object.entries(obj).map(([key, value]) => (
    //                 <li key={key}>
    //                     <strong>{key}:</strong>{' '}
    //                     {Array.isArray(value) ? (
    //                         <ul className="list-disc pl-4">
    //                             {value.map((item, index) => (
    //                                 <li key={index}>
    //                                     {typeof item === 'string' ? breakText(item, 30) : item}
    //                                 </li>
    //                             ))}
    //                         </ul>
    //                     ) : (
    //                         typeof value === 'string' ? breakText(value, 30) : value
    //                     )}
    //                 </li>
    //             ))}
    //         </ul>
    //     );
    // };
    
      
    return (
        <div className="border border-gray-200 relative overflow-x-auto shadow-md rounded ">
            <table className=" w-full text-xs text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs  text-white uppercase bg-[#3A3D89]  border-b border-gray-300">
                    <tr>
                        <th scope="col" className="px-2 py-2">Event</th>
                        <th scope="col" className="px-2 py-2">IP address</th>
                        {!hideUserColumns &&
                            <th scope="col" className="px-2 py-2 w-28 text-center">User Location</th>
                        }

                        <th scope="col" className="px-2 py-2 w-28">User agent</th>
                        <th scope="col" className="px-2 py-2 w-28">Date / Time</th>
                        {!hideUserColumns &&
                            <>
                                <th scope="col" className="px-2 py-2">User</th>
                                <th scope="col" className="px-2 py-2">Role</th>
                            </>
                        }
                        <th scope="col" className="px-2 py-2">Data</th>
                    </tr>
                </thead>
                <tbody>
                    {auditLog !== null && auditLog.length === 0 && <NoResultsTableRow numColumns={numColumns} />}
                    {auditLog !== null && auditLog.map(entry => {
                        return (
                            <tr key={entry.id} className="bg-white border-b border-gray-300 text-black font-medium">
                                <td className="px-2 py-2 ">
                                    <span className="px-2 py-1 inline-flex bg-[#EFEFFF] text-gray-900 rounded border  text-xs">
                                        {entry.action}
                                    </span>
                                </td>
                                <td className="px-2 py-2 "><Ipv4Link value={entry.client_ip} /></td>
                                {!hideUserColumns &&
                                    <td className="px-2 py-2 text-center">
                                        {entry.user_location || 'NA'}
                                    </td>
                                }

                                <td className="px-2 py-2 ">
                                    {entry.user_agent
                                        ? <UserAgentLabel userAgent={entry.user_agent} />
                                        : '-'
                                    }
                                </td>
                                <td className="px-2 py-2 ">
                                    {entry.insert_ts}
                                </td>
                                {!hideUserColumns &&
                                    <>
                                        <td className="px-2 py-2 ">
                                            {entry.user_name ?
                                                <UserLink userId={entry.user_id} fontSize='xs'>{entry.user_name}</UserLink> : '-'
                                            }
                                        </td>
                                        <td className="px-2 py-2 ">
                                            <span className={`text-xs font-semibold ${roleColors(entry.user_role)}`}>
                                                {entry.user_role}
                                            </span>
                                        </td>
                                    </>
                                }
                                <td className="px-2 py-2 ">
                                {entry.object}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default AuditLogsTable;
