import { actionCompletedToast, errorToast } from 'components/ui/toast';
import React, { useState } from 'react'
import TargetKinds from "models/TargetKinds";
import secureApiFetch from 'services/api';
import { FormControl, FormLabel, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import ProcessingLogo from 'components/ui/process logo/ProcessingLogo';

const TargetFormModal = ({ project, isOpen, onSubmit, onCancel, targets }) => {
    const emptyTarget = { project_id: project.id, name: null, kind: TargetKinds[0].value };
    const [target, setTarget] = useState(emptyTarget)
    const [targetName, setTargetName] = useState('')
    const [radioValue, setRadioValue] = useState('1')
    const [parentId, setParentId] = useState('');
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false);

    const handleParentId = (e) => {
        const target = e.target.value;
        setParentId(target)
    }

    const onFormChange = (ev) => {
        const { name, value } = ev.target;
        let newValue = value;
        if (name === 'tags') {
            newValue = JSON.stringify(value.split(','));
        }
        setTarget(prevTarget => ({
            ...prevTarget,
            [name]: newValue,
            ...(radioValue !== '1' && { parent_id: parentId })
        }));
    };

    const onAddTargetFormSubmit = async (ev) => {
        setLoading(true)
        setDisabled(true);
        ev.preventDefault();
        const names = targetName.split(',').map(name => name.trim()).filter(name => name);
        try {
            await Promise.all(
                names.map(name => {
                    const data = { ...target, name: name };
                    return secureApiFetch(`/targets`, {
                        method: 'POST',
                        body: JSON.stringify(data)
                    });
                })
            );
            actionCompletedToast(`The targets have been added.`);
            onSubmit();
            setLoading(false)
        } catch (err) {
            errorToast(err);
        } finally {
            setTarget(emptyTarget);
            onCancel();
            setTimeout(() => setDisabled(false), 2000);
        }
    };


    return (
        <div>
            <form onSubmit={onAddTargetFormSubmit}>
                <RadioGroup onChange={setRadioValue} value={radioValue}>
                    <Stack direction='row'>
                        <Radio value='1'>Target</Radio>
                        <Radio value='2'>Sub-Target</Radio>
                    </Stack>
                </RadioGroup>
                {radioValue === '1' ? (
                    <div></div>
                ) : (
                    <div>
                        <FormControl id="parent_id" isRequired>
                            <FormLabel>Main Target</FormLabel>
                            <select name="parent_id" onChange={handleParentId} placeholder='Please Select the Target' className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'>
                                <option value="">Please select the main Target</option>
                                {targets.filter((name) => name.parent_id === null).map((name, index) =>
                                    <option key={index} value={name.id}>{name.name}</option>
                                )}
                            </select>
                        </FormControl>
                    </div>
                )
                }
                <FormControl id="name" isRequired>
                    <FormLabel>Name</FormLabel>
                    <input name="name" placeholder="e.g. 127.0.0.1" value={targetName} onChange={(e) => setTargetName(e.target.value)} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required />
                </FormControl>
                <FormControl id="tags">
                    <FormLabel>Tags</FormLabel>
                    <input name="tags" placeholder="e.g. linux,production" onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required />
                </FormControl>
                <FormControl id="kind" isRequired>
                    <FormLabel>Kind</FormLabel>
                    <select name="kind" onChange={onFormChange} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'>
                        {TargetKinds.map((targetKind, index) =>
                            <option key={index} value={targetKind.value}>{targetKind.description}</option>
                        )}
                    </select>
                </FormControl>
                <div className='flex justify-end items-center space-x-2'>
                    <button onClick={onCancel} className='bg-red-500  mx-2 px-4 py-2 rounded text-white text-sm'>
                        Cancel
                    </button>
                    <button  disabled={disabled} type='submit' className='bg-[#3A3D89] px-2 py-1.5 rounded text-white space-x-1 flex items-center justify-center text-sm'>
                        Add
                        {loading && (
                            <ProcessingLogo textColor='text-white' />
                        )}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default TargetFormModal