import useFetch from "hooks/useFetch";
import Risks from "models/Risks";

const VulnerabilityProjectFilter = ({ tableModel, tableModelSetter: setTableModel }) => {
    const [categories] = useFetch('/vulnerabilities/categories?parentsOnly=true');

    const onFilterChange = ev => {
        const { name, value } = ev.target;

        setTableModel(prevModel => ({
            ...prevModel,
            filters: {
                ...prevModel.filters,
                [name]: value || null
            }
        }));
    }
    return (
        <div className="flex w-full space-x-3 px-3 pb-2 rounded-lg justify-end">
            <select name="status" onChange={onFilterChange} className="px-2 py-1 border rounded text-sm">
                <option value=''>Status</option>
                <option value='Open'>Open</option>
                <option value='Closed'>Closed</option>
            </select>

            <select name="risk" onChange={onFilterChange} className="px-2 py-1 border rounded text-sm">
                <option value=''>Risk</option>
                {Risks.map(risk => <option key={risk.id} value={risk.id}>{risk.name}</option>)}
            </select>

            <select name="categoryId" onChange={onFilterChange} className="px-2 py-1 border rounded text-sm max-w-32">
                <option value=''>Category</option>
                {categories !== null && categories.map(category => <option key={category.id} value={category.id}>{category.name}</option>)}
            </select>
        </div>
    )
}

export default VulnerabilityProjectFilter