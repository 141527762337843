import { useState, useEffect, useCallback } from "react";
import useFetch from "hooks/useFetch";
import { useNavigate, useLocation } from "react-router-dom";

const ProjectFilters = ({ tableModel, tableModelSetter: setTableModel }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [clients] = useFetch('/clients');
    const [isArchive, setIsArchive] = useState(0);

    const parseHashParams = useCallback(() => {
        const hash = location.hash.substring(1);
        const query = new URLSearchParams(hash);
        const filters = {
            clientId: query.get("clientId") || "",
            projectStatus: query.get("projectStatus") || "",
            orderColumn: query.get("orderColumn") || "",
            status: query.get("status") || 0,
        };
        setIsArchive(Number(filters.status));
        setTableModel((prevModel) => ({
            ...prevModel,
            filters,
        }));
    }, [location.hash, setTableModel]);

    const updateHashParams = useCallback((newFilters) => {
        const query = new URLSearchParams();
        Object.entries(newFilters).forEach(([key, value]) => {
            if (value) {
                query.set(key, value);
            }
        });
        navigate({
            hash: `#${query.toString()}`,
        }, { replace: true });
    }, [navigate]);

    const onFilterChange = (ev) => {
        const { name, value } = ev.target;
        const newFilters = { ...tableModel.filters, [name]: value };
        setTableModel({ ...tableModel, filters: newFilters });
        updateHashParams(newFilters);
    };

    const onIsArchiveChange = (ev) => {
        const value = ev.target.checked ? 1 : 0;
        setIsArchive(value);
        const newFilters = { ...tableModel.filters, status: value };
        setTableModel({ ...tableModel, filters: newFilters });
        updateHashParams(newFilters);
    };

    const clearFilters = () => {
        const defaultFilters = {
            clientId: "",
            projectStatus: "",
            orderColumn: "",
            status: 0,
        };
        setIsArchive(0);
        setTableModel((prevModel) => ({
            ...prevModel,
            filters: defaultFilters,
        }));
        updateHashParams(defaultFilters);
    };

    useEffect(() => {
        parseHashParams();
    }, [parseHashParams]);

    const projectStatusMap = ['To Start', 'On Hold', 'In Progress', 'Completed'];
    const orderChange = [
        { name: 'Updated Time Stamp', value: 'update_ts' },
        { name: 'Insert Time Stamp', value: 'insert_ts' },
        { name: 'Project Status', value: 'project_status' },
        { name: 'Name', value: 'name' },
        { name: 'Engagement Start Date', value: 'engagement_start_date' },
        { name: 'Client ID', value: 'client_id' }
    ];
    return (
        <div className="flex w-full space-x-3 px-3 py-2 rounded-lg justify-end">
            <label className="flex items-center space-x-2 bg-gray-100 px-2 py-1 rounded">
                <input
                    type="checkbox"
                    name="isArchive"
                    checked={isArchive === 1}
                    onChange={onIsArchiveChange}
                    className="form-checkbox w-4 h-4 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                <span>Show Archive</span>
            </label>

            <select name="clientId" onChange={onFilterChange} value={tableModel.filters.clientId} placeholder="Select Client" className="px-2 py-1 border rounded text-sm">
                <option value=''>All Clients</option>
                {clients && clients
                    .filter((project, index, array) => array.findIndex((p) => p.name === project.name) === index)
                    .map((client) => (
                        <option key={client.name} value={client.id}>{client.name}</option>
                    ))}
            </select>

            <select name="projectStatus" onChange={onFilterChange} value={tableModel.filters.projectStatus} placeholder="Select Status" className="px-2 py-1 border rounded text-sm">
                <option value=''>All Status</option>
                {projectStatusMap.map(statusName => (
                    <option key={statusName} value={statusName}> {statusName}</option>
                ))}
            </select>

            <select name="orderColumn" onChange={onFilterChange} value={tableModel.filters.orderColumn} placeholder="Select Order By" className="px-2 py-1 border rounded text-sm">
                {orderChange.map(statusName => (
                    <option key={statusName.value} value={statusName.value}> {statusName.name}</option>
                ))}
            </select>
            <button
                onClick={clearFilters}
                className='bg-[#3A3D89] px-2 py-1 rounded text-white space-x-1 flex items-center text-sm'
            >
                Clear Filters
            </button>
        </div>
    );
};

export default ProjectFilters;
