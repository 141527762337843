import { Link } from "react-router-dom";

import DataUsageOutlinedIcon from '@mui/icons-material/DataUsageOutlined';
import FileSizeSpan from "components/ui/FileSizeSpan";
import Loading from "components/ui/Loading";
import useFetch from "hooks/useFetch";

import Breadcrumb from "../ui/Breadcrumb";
import Configuration from "Configuration";
import Title from "../ui/Title";

const SystemUsagePage = () => {
    const [usage] = useFetch('/system/usage');
    const configDetails = [
        { label: 'Client URL', value: Configuration.getClientUrl() },
        { label: 'API URL', value: Configuration.getDefaultApiUrl() },
        { label: 'Notifications Service Host', value: Configuration.getNotificationsServiceHostPort() },
        { label: 'Agent Service Host', value: Configuration.getAgentServiceHostPort() },
    ];

    const keycloakConfig = Configuration.getKeycloakConfig();
    const logoUrl = Configuration.getLogoUrl();

    if (!usage) return <Loading />
    return (
        <div>
            <div className='heading'>
                <Breadcrumb>
                    <Link to='/system'> System </Link>
                    <div>Usage</div>
                </Breadcrumb>
            </div>
            <Title type="System" title="Usage" icon={<DataUsageOutlinedIcon />} />
            <hr />
            <div className="flex justify-between pr-10 pt-2">
                <div className="">
                    <h2 className="text-base font-semibold mb-4 text-gray-800">Endpoint Details</h2>
                    <ul className="space-y-3">
                        {configDetails.map((detail, index) => (
                            <li key={index} className="flex items-center">
                                <span className="font-medium text-gray-700 text-sm">{detail.label}:</span>
                                <a href={detail.value} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline break-all ml-2 text-sm">
                                    {detail.value}
                                </a>
                            </li>
                        ))}
                    </ul>

                    <h3 className="text-base font-semibold mt-6 mb-2 text-gray-800">Keycloak Configuration</h3>
                    <ul className="space-y-3">
                        {Object.entries(keycloakConfig).map(([key, value], index) => (
                            <li key={index} className="flex items-center">
                                <span className="font-medium text-gray-700 text-sm">{key}:</span>
                                {typeof value === 'string' && value.startsWith('http') ? (
                                    <a href={value} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline break-all ml-2 text-sm">
                                        {value}
                                    </a>
                                ) : (
                                    <span className="text-gray-600 break-all ml-2 text-sm">{value}</span>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="">
                    <h2 className="text-base font-semibold mb-1 text-gray-800">Attachments count</h2>
                    <span className="font-medium text-gray-700 text-sm ml-2">{usage.attachments.total_count} total</span>

                    <h2 className="text-base font-semibold mt-4 mb-2 text-gray-800">Attachments total disk usage</h2>
                    <span className="font-medium text-gray-700 text-sm ml-2">
                        <FileSizeSpan fileSize={usage.attachments.total_file_size} />
                    </span>

                    <div className="mt-6">
                        <label className="text-base font-semibold text-gray-800">
                            Logo Url:  <span className="font-medium text-gray-700 text-sm"> {logoUrl} </span>
                        </label>
                        <div>
                            <img src={logoUrl} alt="logo" className="w-40 mt-2" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SystemUsagePage;
