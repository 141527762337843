import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { actionCompletedToast, errorToast } from "../ui/toast";
import { HStack, Switch, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RiskBadge from "components/badges/RiskBadge";
import ExternalLink from "components/ui/ExternalLink";
import Tags from 'components/ui/Tags';
import AttachmentsTable from 'components/attachments/AttachmentsTable';
import AttachmentsDropzone from 'components/attachments/Dropzone';
import PageTitle from 'components/logic/PageTitle';
import RestrictedComponent from 'components/logic/RestrictedComponent';
import VulnerabilityStatuses from 'models/VulnerabilityStatuses';

import { TabsUrlVulnerability } from "../../models/TabsUrl"
import secureApiFetch from '../../services/api';
import Breadcrumb from '../ui/Breadcrumb';
import Loading from '../ui/Loading';
import Title from '../ui/Title';
import useDelete from './../../hooks/useDelete';
import useFetch from './../../hooks/useFetch';
import VulnerabilitiesNotesTab from "./NotesTab";
import VulnerabilityDescriptionPanel from './VulnerabilityDescriptionPanel';
import VulnerabilityRemediationPanel from './VulnerabilityRemediationPanel';
import VulnerabilityStatusBadge from "./StatusBadge";
import { useCallback, useEffect } from 'react';

const VulnerabilityDetails = () => {
    const navigate = useNavigate();
    const { vulnerabilityId } = useParams();
    const [vulnerability, updateVulnerability] = useFetch(`/vulnerabilities/${vulnerabilityId}`)
    const deleteVulnerability = useDelete(`/vulnerabilities/`)

    const location = useLocation();
    const parentType = 'vulnerability';
    const parentId = vulnerabilityId;
    const [attachments, reloadAttachments] = useFetch(`/attachments?parentType=${parentType}&parentId=${parentId}`)

    const tabFromUrl = location.pathname.split('/').pop();
    const currentTabFromUrl = TabsUrlVulnerability.indexOf(tabFromUrl) !== -1
        ? TabsUrlVulnerability.indexOf(tabFromUrl)
        : 0;
    const handleTabChange = useCallback((index) => {
        navigate(`/vulnerabilities/${vulnerabilityId}/${TabsUrlVulnerability[index]}`);
    }, [navigate, vulnerabilityId]);

    useEffect(() => {
        if (currentTabFromUrl !== -1) {
            handleTabChange(currentTabFromUrl);
        }
    }, [location.pathname, currentTabFromUrl, handleTabChange]);

    const handleDelete = async () => {
        const confirmed = await deleteVulnerability(vulnerabilityId);
        if (confirmed)
            navigate('/vulnerabilities')
    }

    const onStatusChange = ev => {
        const [status, substatus] = ev.target.value.split('-');
        secureApiFetch(`/vulnerabilities/${vulnerability.id}`, {
            method: 'PATCH',
            body: JSON.stringify({ status, substatus })
        })
            .then((response) => {
                if (response.status === 200) {
                    actionCompletedToast("The status has been transitioned.");
                    updateVulnerability();
                } else {
                    errorToast("Failed to update the status. Please try again.");
                }
            })
            .catch(err => console.error(err))
    }

    const onVisibilityToggle = (id, isChecked) => {
        const newVisibility = isChecked ? 'public' : 'private';
        secureApiFetch(`/vulnerabilities/${id}`, {
            method: 'PUT',
            body: JSON.stringify({ visibility: newVisibility })
        })
            .then(response => {
                if (response.ok) {
                    updateVulnerability()
                } else {
                    console.error('Error updating visibility:', response.status);
                }
            })
            .catch(error => {
                console.error('Error updating visibility:', error);
            });
    };

    if (!vulnerability) return <Loading />

    if (vulnerability && vulnerability.is_template) {
        return <Navigate to={`/vulnerabilities/templates/${vulnerability.id}`} />
    }
    return <div>
        <div className='heading'>
            <Breadcrumb>
                <Link to="/vulnerabilities">Vulnerabilities</Link>
                <span>Details</span>
            </Breadcrumb>
            <HStack >
                <RestrictedComponent roles={['administrator', 'superuser', 'user']}>
                    <select className='pl-2 py-1.5 border rounded text-sm font-medium focus:outline-none' onChange={onStatusChange} value={vulnerability.status + '-' + vulnerability.substatus}>
                        <option value="">Update Status</option>
                        {VulnerabilityStatuses.map(status =>
                            <option key={`vulnstatus_${status.id}`} value={status.id}>{status.name}</option>
                        )}
                    </select>
                    <div className="border px-2 py-1.5 rounded flex items-center">
                        <label className='font-medium'>
                            {vulnerability.visibility === 'public' ? 'Published' : 'Private'}
                        </label>
                        <Switch isChecked={vulnerability.visibility === 'public'} onChange={(event) => onVisibilityToggle(vulnerability.id, event.target.checked)} />
                    </div>

                    <button className='bg-[#3A3D89] px-2 py-1.5 rounded text-white space-x-1 flex items-center text-sm' onClick={(ev) => {
                        ev.preventDefault();
                        navigate(`/vulnerabilities/${vulnerability.id}/edit`)
                    }}>
                        Edit
                    </button>
                    <button onClick={handleDelete} className='bg-[#EB1F36] px-2 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>
                        <DeleteIcon fontSize='small' />  Delete
                    </button>


                </RestrictedComponent>
            </HStack>
        </div>
        <article>
            <PageTitle value={`${vulnerability.summary} vulnerability`} />

            <Title type='Vulnerability' title={vulnerability.external_id ? <><strong>{vulnerability.external_id.toUpperCase()} |</strong>&nbsp;{vulnerability.summary}</> : vulnerability.summary} icon={<InfoOutlinedIcon />} />

            <div className='flex space-x-3 items-center'>
                {vulnerability.visibility === 'private' ? <span className="bg-red-500 px-1.5 py-1 rounded text-white text-xs">
                    Private
                </span> : <span className="bg-[#48A14D] px-1.5 py-1 rounded text-white text-xs">
                    Published
                </span>}
                <span>
                    <RiskBadge risk={vulnerability.risk} score={vulnerability.cvss_score} />
                </span>
                <span>
                    <VulnerabilityStatusBadge vulnerability={vulnerability} />
                </span>
                {vulnerability.cvss_vector &&
                    <span className='inline-flex  items-center text-gray-800 bg-gray-100 rounded-md hover:bg-gray-200 hover:text-gray-600'>
                        <ExternalLink href={`https://www.first.org/cvss/calculator/3.0#${vulnerability.cvss_vector}`}>{vulnerability.cvss_vector}</ExternalLink>
                    </span>
                }
                <Tags values={vulnerability.tags} />

            </div>

            <Tabs varient='enclosed' index={currentTabFromUrl} onChange={handleTabChange}>
                <TabList>
                    <Tab><span className='text-xs font-medium'>Description</span></Tab>
                    <Tab><span className='text-xs font-medium'>Remediation</span></Tab>
                    <Tab><span className='text-xs font-medium'>Discussion</span></Tab>
                    <Tab><span className='text-xs font-medium'>Attachments</span></Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <VulnerabilityDescriptionPanel vulnerability={vulnerability} />
                    </TabPanel>
                    <TabPanel>
                        <VulnerabilityRemediationPanel vulnerability={vulnerability} />
                    </TabPanel>
                    <TabPanel>
                        <VulnerabilitiesNotesTab vulnerability={vulnerability} />
                    </TabPanel>
                    <TabPanel>
                        {attachments === null ?
                            <h1 className='text-gray-600  font-medium text-center w-full'>You don't have access.</h1> : (
                                <>
                                    <AttachmentsDropzone parentType={parentType} parentId={parentId} onUploadFinished={reloadAttachments} />
                                    <h4><AttachmentOutlinedIcon className='text-[#3A3D89]' />Attachment list</h4>
                                    <AttachmentsTable attachments={attachments} reloadAttachments={reloadAttachments} />
                                </>
                            )}
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </article>

    </div >
}

export default VulnerabilityDetails;
