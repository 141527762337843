import UAParser from 'ua-parser-js';
import { Tooltip } from '@chakra-ui/react'

const UserAgentLabel = ({ userAgent }) => {
    const parser = new UAParser(userAgent);
    const browserName = parser.getBrowser().name;

    let description = null;
    if (browserName) {
        description = `${parser.getBrowser().name} on ${parser.getOS().name}`;
    } else {
        description = userAgent;
    }

    return (
        <Tooltip label={userAgent} fontSize='xs'>
            <span>{description}</span>
        </Tooltip>
    )
}

export default UserAgentLabel;
