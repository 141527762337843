// import Configuration from "Configuration";
import Configuration from "Configuration";


const HeaderLogo = () => {
    let imgPath = Configuration.getLogoUrl()
    return <h3>
        <img alt="Prosecurity Logo" src={imgPath} />
    </h3>
}

export default HeaderLogo;
