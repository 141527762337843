import useFetch from "hooks/useFetch";
import PropTypes from 'prop-types';
import DashboardWidget from "./Widget";
import Chart from 'react-apexcharts';

const VulnerabilitiesByCategoryStatsWidget = ({ projectId = null }) => {

    const url = '/vulnerabilities/stats?groupBy=category' + (null !== projectId ? '&projectId=' + encodeURIComponent(projectId) : '');
    const [vulnerabilitiesByCategoryStats] = useFetch(url)

    const options = {
        chart: {
            type: 'pie',
        },
        labels: vulnerabilitiesByCategoryStats ? vulnerabilitiesByCategoryStats.map(item => item.category_name) : [],
        legend: {
            show:false
        },
        responsive: [{
            breakpoint: 300,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    const chartSeries = vulnerabilitiesByCategoryStats ? vulnerabilitiesByCategoryStats.map(item => item.total) : [];

    return (
        <DashboardWidget title="Vulnerabilities by category">
            {vulnerabilitiesByCategoryStats && vulnerabilitiesByCategoryStats.length > 0 ?
                <Chart options={options} series={chartSeries} type="donut" height={300} />
                :
                <p className="m-2 font-medium">No enough data to generate the chart.</p>
            }
        </DashboardWidget>
    )
}

VulnerabilitiesByCategoryStatsWidget.propTypes = {
    projectId: PropTypes.number
};

export default VulnerabilitiesByCategoryStatsWidget;
