import React, { useEffect, useState } from 'react';
import { TaskPriorityList } from "../../models/TaskPriority";

import AsyncSelect from 'react-select/async';
import MarkdownEditor from "components/ui/forms/MarkdownEditor";
import Loading from "components/ui/Loading";
import secureApiFetch from "services/api";

import useFetch from "../../hooks/useFetch";

const TaskForm = ({ isEditForm = false, forTemplate = false, onFormSubmit, task, taskSetter: setTask }) => {
    const [projects] = useFetch('/projects?isTemplate=' + (forTemplate ? 1 : 0));
    const [commands] = useFetch('/commands');

    const onFormChange = ev => {
        const target = ev.target;
        const name = target.name;
        const value = target.value;
        setTask({ ...task, [name]: value });
    };

    const initialCommand = { id: task.command_id, name: task.command_name };
    const [selectedCommand, setSelectedCommand] = useState(initialCommand);


    const onCommandChange = command => {
        setSelectedCommand(command);
        setTask({ ...task, command_id: command.id });
    }


    // Define the state and function for the Main Task selection
    const [selectedMainTask, setSelectedMainTask] = useState(null);

    const onMainTaskChange = (mainTask) => {
        setSelectedMainTask(mainTask);
        setTask({ ...task, maintask: mainTask.id });
    }

    // Define the loadOptions function for Main Task selection
    // Define the loadOptions function for Main Task selection
    const loadMainTaskOptions = async (keywords) => {
        const projectId = task.project_id; // Get the projectId from the current task
        const tasksResponse = await secureApiFetch(`/tasks?maintask=true&projectId=${projectId}`, { method: 'GET' });
        const tasks = await tasksResponse.json();

        // Add the "undefined" option to the fetched tasks
        const options = [
            { id: undefined, summary: "undefined" },
            ...tasks,
        ];

        return options;
    };



    useEffect(() => {
        if (projects !== null && projects.length && task.project_id === "") {
            const newProjectId = projects[0].id;
            setTask(prevTask => ({ ...prevTask, project_id: newProjectId }));
        }
    }, [task.project_id, projects, setTask]);

    const loadOptions = (keywords) => {
        return secureApiFetch(`/commands?keywords=` + encodeURIComponent(keywords), { method: 'GET' }).then(data => data.json());
    }

    if (!commands) return <Loading />

    return (
        <form onSubmit={onFormSubmit}>
            <hr className='h-px bg-[#3A3D89]' />
            <label>
                <span className="font-medium text-xs">Project</span>

                <select name="project_id" onChange={onFormChange} value={task.project_id} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' required>
                    {projects && projects.map((project, index) =>
                        <option key={index} value={project.id}>{project.name}</option>
                    )}
                </select>
            </label>
            <label>
                <span className="font-medium text-xs">Main Task</span>
                <AsyncSelect
                    name="maintask"
                    value={selectedMainTask}
                    defaultOptions={true}
                    loadOptions={loadMainTaskOptions}
                    getOptionLabel={opt => opt.summary}
                    getOptionValue={opt => opt.id}
                    onChange={onMainTaskChange}
                    isClearable
                />
            </label>

            <label>
                <span className="font-medium text-xs">Title</span>
                <input type="text" name="summary" onChange={onFormChange} required autoFocus value={task.summary} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
            </label>

            <label>
                <span className="font-medium text-xs">Command</span>
                <AsyncSelect
                    name="command_id"
                    value={selectedCommand}
                    defaultOptions={true}
                    loadOptions={loadOptions}
                    getOptionLabel={opt => opt.name}
                    getOptionValue={opt => opt.id}
                    onChange={onCommandChange}
                    isClearable
                />
            </label>

            <label>
                <span className="font-medium text-xs">Description</span>
                <MarkdownEditor name="description" onChange={onFormChange} required value={task.description || ''} />
            </label>

            <label>
                <span className="font-medium text-xs">Priority</span>
                <select variant='filled' borderColor="gray.400" name="priority" onChange={onFormChange} value={task.priority || "medium"} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none'>
                   <option value="">Select Priority</option>
                    {TaskPriorityList.map(priority =>
                        <option key={priority.value} value={priority.value}>{priority.name}</option>
                    )}
                </select>
            </label>
            <label>
                <span className="font-medium text-xs">Due date</span>
                <input variant='filled' borderColor="gray.400" type="date" name="due_date" onChange={onFormChange} value={task.due_date} className='px-2 py-1.5 border rounded text-sm font-medium focus:outline-none' />
            </label>

            <div className="fixed bottom-4 right-4">
                <button type='submit' className='bg-[#3A3D89] px-4 py-2 rounded text-white text-sm'>
                    {isEditForm ? "Update Task" : "Create Task"}
                </button>
            </div>
        </form>
    );
}

export default TaskForm;
