import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import PaginationV2 from 'components/layout/PaginationV2';
import RestrictedComponent from 'components/logic/RestrictedComponent';
import secureApiFetch from 'services/api';
import useDelete from '../../hooks/useDelete';
import Breadcrumb from '../ui/Breadcrumb';
import CreateButton from '../ui/buttons/Create';
import Title from '../ui/Title';
import ProjectsTable from './Table';
import ProjectFilters from './Filter'
import ProjectTableModel from './ProjectTableModel'
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

const ProjectsList = () => {
    const navigate = useNavigate();
    const query = useQuery();
    let pageNumber = query.get('page');
    pageNumber = pageNumber !== null ? parseInt(pageNumber) : 1;
    const apiPageNumber = pageNumber - 1;

    const [numberPages, setNumberPages] = useState(1);
    const [totalCount, setTotalCount] = useState('?');

    const [tableModel, setTableModel] = useState(new ProjectTableModel());

    const handleCreateProject = () => {
        navigate('/projects/create')
    }
    const onPageChange = pageNumber => {
        const queryParams = new URLSearchParams();
        queryParams.set('page', pageNumber + 1);
        const url = `/projects?${queryParams.toString()}`;
        navigate(url);
    }

    const reloadProjects = useCallback(() => {
        setTableModel(tableModel => ({ ...tableModel, projects: null }));
        const queryParams = new URLSearchParams();
        queryParams.set('page', apiPageNumber);
        queryParams.set('orderDirection', tableModel.sortBy.order);
        if(!tableModel.filters.orderColumn){
            queryParams.set('orderColumn', "update_ts");
        }
        if(!tableModel.status){
            queryParams.set('status', "0");
        }
        Object.keys(tableModel.filters)
            .forEach(key => tableModel.filters[key] !== null && tableModel.filters[key].length !== 0 && queryParams.append(key, tableModel.filters[key]));
        const url = `/projects?${queryParams.toString()}`;
        secureApiFetch(url)
            .then(resp => {
                if (resp.headers.has('X-Page-Count')) {
                    setNumberPages(resp.headers.get('X-Page-Count'))
                }
                if (resp.headers.has('X-Total-Count')) {
                    setTotalCount(resp.headers.get('X-Total-Count'))
                }
                return resp.json()
            })
            .then(data => {
                setTableModel(tableModel => ({ ...tableModel, projects: data }));
            });
    }, [setTableModel, apiPageNumber, tableModel.filters,tableModel.status, tableModel.sortBy.order]);

    const destroy = useDelete('/projects/', reloadProjects);

    useEffect(() => {
        reloadProjects();
    }, [reloadProjects,tableModel.filters]);

    return (
        <div>
            <div className='heading'>
                <Breadcrumb>
                    <Link to="/projects">Projects</Link>
                </Breadcrumb>
                <PaginationV2 page={apiPageNumber} total={numberPages} onPageChange={onPageChange} />
                <RestrictedComponent roles={['administrator', 'superuser', 'client']}>
                    <CreateButton onClick={handleCreateProject}>Create project</CreateButton>
                </RestrictedComponent>
            </div>
            <Title title={`Projects (${totalCount})`} icon={<FolderOpenIcon />} />
            <div>
                <ProjectFilters tableModel={tableModel} tableModelSetter={setTableModel} />
            </div>
            <ProjectsTable tableModel={tableModel} tableModelSetter={setTableModel} destroy={destroy} />
        </div>
    )
}

export default ProjectsList;
