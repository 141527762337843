import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { actionCompletedToast, errorToast } from 'components/ui/toast';

import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import RestrictedComponent from 'components/logic/RestrictedComponent';
import TaskTableModel from 'components/tasks/TaskTableModel';
import useDelete from 'hooks/useDelete';
import secureApiFetch from 'services/api';
import TaskStatuses from "../../models/TaskStatuses";

import ProcessingLogo from 'components/ui/process logo/ProcessingLogo';
import taskData from 'components/tasks/TaskDataSample';
import TaskProjectFilter from './TaskProjectFilter';
import TaskTableProject from './TaskTableProject';

const ProjectTasks = ({ project }) => {
    const navigate = useNavigate();
    const isTemplate = project.is_template === 1;
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [tableModel, setTableModel] = useState(new TaskTableModel(false, false))
    const [fileValue, setFileValue] = useState(null)
    const [loading, setLoading] = useState(false)

    const reloadTasks = useCallback(() => {
        setTableModel(tableModel => ({ ...tableModel, tasks: null }));

        const queryParams = new URLSearchParams();
        queryParams.set('projectId', project.id);
        queryParams.set('orderColumn', tableModel.sortBy.column);
        queryParams.set('orderDirection', tableModel.sortBy.order);
        queryParams.set('isTemplate', isTemplate);
        Object.keys(tableModel.filters)
            .forEach(key => tableModel.filters[key] !== null && tableModel.filters[key].length !== 0 && queryParams.append(key, tableModel.filters[key]));
        const url = `/tasks?${queryParams.toString()}`;

        secureApiFetch(url, { method: 'GET' })
            .then(resp => {
                return resp.json()
            })
            .then(data => {
                setTableModel(tableModel => ({ ...tableModel, tasks: data }));
            });
    }, [setTableModel, project.id, tableModel.filters, tableModel.sortBy.column, tableModel.sortBy.order, isTemplate]);

    const onAddTaskClick = ev => {
        ev.preventDefault();
        navigate(`/tasks/create?projectId=${project.id}&forTemplate=${project.is_template}`);
    }

    const handleFile = e => {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            const fileContent = e.target.result;
            const tasksArray = JSON.parse(fileContent).Tasks;
            const newProjectId = tasksArray.map((item) => {
                return {
                    ...item,
                    project_id: project.id
                }
            })
            setFileValue(newProjectId);
        };
    };


    const handleUpload = (event) => {
        event.preventDefault();
        setLoading(true)
        fileValue.forEach((task) => {
            secureApiFetch(`/tasks`, { method: 'POST', body: JSON.stringify(task) })
                .then((res) => {
                    if (res.status === 200) {
                        actionCompletedToast(`The task has been created.`);
                    } else if (res.status === 400) {
                        errorToast(`The format of the data is wrong.`);
                    }
                    else {
                        errorToast(`The task has been not created.`);
                    }
                    onClose()
                    return res.json()
                })
                .then((data) => {
                    setLoading(false)
                    reloadTasks()
                    const subtsk = task.subtasks
                    const newTask = subtsk.map((item) => {
                        return {
                            ...item,
                            maintask: data.task_id,
                            project_id: project.id
                        }
                    })
                    if (task && subtsk) {
                        newTask.forEach((task) => {
                            secureApiFetch(`/tasks`, { method: 'POST', body: JSON.stringify(task) })
                        })
                            .then((res) => {
                                if (res.status === 200) {
                                    actionCompletedToast(`The task has been created.`);
                                } else if (res.status === 400) {
                                    errorToast(`The format of the data is wrong.`);
                                }
                                else {
                                    errorToast(`The task has been not created.`);
                                }
                                onClose()
                                setLoading(false)
                            })
                    }
                })
        })
    }

    const onDeleteTask = useDelete('/tasks/', reloadTasks);
    const onStatusSelectChange = (ev) => {
        const newStatus = ev.target.value;
        secureApiFetch('/tasks', {
            method: 'PATCH',
            headers: {
                'Bulk-Operation': 'UPDATE',
            },
            body: JSON.stringify({
                taskIds: tableModel.selection,
                newStatus: newStatus
            })
        })
            .then(reloadTasks)
            .then(() => {
                setTableModel(prevTableModel => ({
                    ...prevTableModel,
                    selection: []
                }));
                actionCompletedToast(`All selected tasks have been transitioned to "${newStatus}".`);
                ev.target.value = '';
            })
            .catch(err => console.error(err));
    }
    useEffect(() => {
        reloadTasks()
    }, [reloadTasks, tableModel.filters])

    return <section className='-mt-1'>
        <h4 className='text-sm'>
            <AssignmentOutlinedIcon sx={{ color: '#3A3D89' }} /> Tasks {!isTemplate &&
                <>&nbsp;<small>({tableModel.tasks && tableModel.tasks.reduce((total, task) => {
                    return task.status === 'Done' ? total + 1 : total;
                }, 0)}/{tableModel.tasks && tableModel.tasks.length} completed)</small></>}
            <RestrictedComponent roles={['administrator', 'superuser', 'user']}>
                <div className='flex space-x-2 items-center mb-2'>
                    <button type="button" onClick={onAddTaskClick} className='bg-[#3A3D89] px-2 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>
                        Add Task
                    </button>
                    <button type="button" onClick={onOpen} className='bg-[#3A3D89] px-2 py-1.5 rounded text-white space-x-1 flex items-center text-sm'>
                        Upload Task
                    </button>
                    <div>
                    <select disabled={!tableModel.selection.length} onChange={onStatusSelectChange} className='px-2 py-1 border rounded text-sm w-36 font-medium'>
                        <option value="">(select option)</option>
                        {TaskStatuses.map((status, index) =>
                            <option key={index} value={status.id}>{status.name}</option>
                        )}
                    </select>
                </div>

                </div>
            </RestrictedComponent>
        </h4>

        <Flex >
            <TaskProjectFilter tableModel={tableModel} tableModelSetter={setTableModel} />
        </Flex>

        <TaskTableProject tableModel={tableModel} tableModelSetter={setTableModel} destroy={onDeleteTask} reloadCallback={reloadTasks}/>
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Upload Tasks</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <div className='flex justify-between items-center'>
                            <label className="block mb-2 text-sm font-medium text-gray-900 " for="file_input">Upload file</label>
                            <a href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(taskData))}`} download="SampleData.json"
                                className='bg-[#3A3D89] px-2 py-0.5 mx-2 rounded text-white space-x-1 flex items-center text-xs'>
                                Download Sample File
                            </a>
                        </div>
                        <form>
                            <label for="file-input" className="sr-only">Choose file</label>
                            <input onChange={handleFile} type="file" name="file-input" id="file-input" className="block w-full border border-gray-200 shadow-sm rounded-lg text-sm disabled:opacity-50 disabled:pointer-events-none file:bg-gray-100 file:border-0 file:me-4 file:py-3 file:px-4 " />
                            <p className="mt-1 text-sm text-gray-500 " id="file_input_help">File Supported: .json</p>
                        </form>
                    </ModalBody>

                    <ModalFooter>
                        <button onClick={handleUpload} className='bg-[#3A3D89] px-2 py-1.5 rounded text-white space-x-1 flex items-center justify-center text-xs'>
                            Create Tasks
                            {loading && (
                                <ProcessingLogo textColor='text-white' />
                            )}
                        </button>
                        <button onClick={onClose} className='bg-red-600 px-2 py-1.5 mx-2 rounded text-white space-x-1 flex items-center text-xs' >Cancel</button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    </section>
}

export default ProjectTasks;
